
<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="45" height="36">

        <g transform="matrix(0.10000001 0 0 0.10000001 0 0)">
            <path d="M0 20C 0 8.954305 8.954305 0 20 0L20 0L430 0C 441.0457 0 450 8.954305 450 20L450 20L450 340C 450 351.0457 441.0457 360 430 360L430 360L20 360C 8.954305 360 0 351.0457 0 340L0 340L0 20z" stroke="none" :fill="secondaryColor" :style="{opacity: opacity}" fill-rule="nonzero" />
            <path d="M309.383 173.741C 314.206 176.523 314.206 183.477 309.383 186.259L309.383 186.259L172.85 265.021C 168.028 267.803 162 264.325 162 258.762L162 258.762L162 101.238C 162 95.6745 168.028 92.1972 172.85 94.9791L172.85 94.9791L309.383 173.741z" stroke="none" :fill="color" fill-rule="nonzero" />
        </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'PlayMinimal',
    props: {
      color: {
        type: String,
        required: true,
      },
      secondaryColor: {
        type: String,
        required: true,
      },
      opacity: {
        type: Number,
        default: 1
      },
    },
  }
  </script>
  
  <style lang="css" scoped>
  </style>